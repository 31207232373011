import { useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { TokensContext } from '../Context/TokensContext';
import { ChatContext } from '../Context/ChatContext';
import { AuthContext } from '../Context/AuthContext';

const useHandleSendMessage = ({ 
    allMessages, setAllMessages,
    messageInput,setMessageInput,
    filePreview, setFilePreview,
    error, setError,
    file, setFile,
    fileError,
    showUploadPrompt, setShowUploadPrompt,
    isLoading, setIsLoading,
 }) => {

    const { tokens, setTokens } = useContext(TokensContext);
    const { surveyData} = useContext(ChatContext);
    const navigate = useNavigate();
    const messages = [];
    const {isLoggedIn} = useContext(AuthContext);

    const getCurrentTime = () => {
        const now = new Date()
            .toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
        return now;
    };
    
    const getLastUserMessage = () => {
        const userMessages = allMessages.filter(message => message.role === 'user');
        return userMessages.length > 0 ? userMessages[userMessages.length - 1].text : '';
      };

        // Send user message to backend and receive bot response
        const handleSendMessage = async (e, regenerate = false) => {
            e.preventDefault();

            if (isLoading) {
                return;
            }
            
            const userMessage = regenerate ? getLastUserMessage() : messageInput;
            
            if((userMessage == "") && !filePreview) {
                return; 
            } 
            if (fileError) {
                return;
            }

            // Redirect to summary page if tokens run out
            if (tokens <= 0) {
                console.log("Token limit reached");
                navigate('/summary');
                return;
            }

            console.log("Logged in?", isLoggedIn);
            console.log("User email (if logged in):" , surveyData['email']);
            
            
            // console.log("All msg:", allMessages);
            const chatMessages = allMessages;

            try {
                setIsLoading(true);
                setError(null);
    
                let newMessage = null;
                if (filePreview && userMessage == '') {
                    newMessage = {
                        role: 'user',
                        content: 'Uploaded file:',
                        time: getCurrentTime(),
                        tokensLeft: tokens, 
                        file: filePreview, 
                    };
                } else {
                    newMessage = {
                        role: 'user',
                        content: userMessage,
                        time: getCurrentTime(),
                        tokensLeft: tokens, 
                        file: filePreview, 
                    };
                }

                
                const updatedMessages = [...allMessages, newMessage];
                setAllMessages(updatedMessages);

                // Messages being sent to backend
                messages.push({
                    role: 'user',
                    content: userMessage,
                    time: getCurrentTime(),
                    file: filePreview || null
                    
                });

                chatMessages.push({
                    role: 'user',
                    content: userMessage,
                }
                )
                
                const formData = new FormData();
                if (file) {
                    formData.append('file', file);
                    console.log("Selected file: ", file.name);
                }
                
                formData.append('messages', JSON.stringify(messages));
                formData.append('data', JSON.stringify(surveyData));
                formData.append('chatMessages', JSON.stringify(chatMessages));
    
                
                const response = await axios.post(`${process.env.REACT_APP_CHATAGENT_URI}/chatagent/`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });
                
                // Log the response from the backend
                console.log('Response.data from backend:', response.data.response);
        
                // Update the UI with the received AI response
                setAllMessages([...updatedMessages, 
                    { role: response.data.response.role, 
                        content: response.data.response.text, 
                        liked: false, disliked: false, 
                        id: response.data.aiMessageId, 
                        time: getCurrentTime(), }]);

                // Update user message with message id 
                messages[messages.length - 1] = {
                    ...messages[messages.length - 1],
                    id: response.data.userMessageId,
                };

                // Add AI message with message id 
                messages.push({
                    role: response.data.response.role,
                    content: response.data.response.text,
                    liked: false,
                    disliked: false,
                    id: response.data.aiMessageId, 
                    time: getCurrentTime(),
                });

                // Show upload prompt from AI 
                if (!error &&!isLoading && !showUploadPrompt && response.data.response.role === 'assistant') {
                    setShowUploadPrompt(true);
                }
    
                // Decrement tokens after a successful response
                if (response.data.response.role === 'assistant') {
                    setTokens(tokens - 1);
                }
    
                setMessageInput('');
                setFile(null);
                setFilePreview(null);

            } catch (e) {
                console.error('Error sending message:', e); 
                setError('Server error. Please try again.');
            
                
            } finally {
                setIsLoading(false)
            }
            };

            return {
                handleSendMessage
            };
}

export default useHandleSendMessage;