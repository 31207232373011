
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Login from './Pages/Login/Login';
import Home from './Pages/Home/Home';
import Signup from './Pages/Signup/Signup';
import Chatbot from './Pages/Chatbot/Chatbot';
import Summary from './Pages/Summary/Summary';
import LinkedInAuth from './Auth/LinkedInAuth';
import GoogleAuth from './Auth/GoogleAuth';
import ForgotPassword from './Pages/ForgotPassword/ForgotPassword';
import Profile from './Pages/Profile/Profile';

function App() {
  return (
    <div className="App">
    <Router>
      <Routes>
        <Route path="/" element={<Home />}/>
        <Route path="/login" element={<Login />}/>
        <Route path="/forgot-password" element={<ForgotPassword/>}/>
        <Route path = "/auth/linkedin/callback" element={<LinkedInAuth/>}/>
        <Route path = "/auth/google/callback" element={<GoogleAuth/>}/>
        <Route path="/signup" element={<Signup />}/>
        <Route path="/chatbot" element={<Chatbot />}/>
        <Route path="/summary" element={<Summary />}/>
        <Route path="/profile" element={<Profile />}/>

      </Routes>
    </Router>
    </div>
  );
}

export default App;
