import React, { useContext, useState } from 'react';
import './Profile.css'
import { Link } from 'react-router-dom';
import { AuthContext } from '../../Context/AuthContext';
import NavBar from '../../Components/NavBar/NavBar';

function Profile() {
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [industry, setIndustry] = useState("");
    const [role, setRole] = useState("");
    const [profilePic, setProfilePic] = useState("");

    const handleProfilePicChange = (e) => {
        const file = e.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onloadend = () => {
            setProfilePic(reader.result);
          };
          reader.readAsDataURL(file);
        }
      };
    
      const handleSubmit = (e) => {
        e.preventDefault();
        // Implement API call to update user info here
        console.log({
          name,
          email,
          password,
          industry,
          role,
          profilePic
        });
      };

    return (
      <div className="profile-page">
        <NavBar />
        <div className="profile-container">
        <h2 className="profile-title">Edit Profile</h2>
        <form onSubmit={handleSubmit} className="profile-form">
          <div className="form-group">
            <label htmlFor="profilePic">Profile Picture</label>
            <input type="file" accept="image/*" className="file-input" onChange={handleProfilePicChange} />
            {profilePic && <img src={profilePic} alt="Profile" className="profile-pic-preview" />}
          </div>
          
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="email">Email Address</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Leave blank to keep current password"
            />
          </div>

          <div className="form-group">
            <label htmlFor="industry">Industry</label>
            <input
              type="text"
              id="industry"
              value={industry}
              onChange={(e) => setIndustry(e.target.value)}
            />
          </div>

          <div className="form-group">
            <label htmlFor="role">Role</label>
            <input
              type="text"
              id="role"
              value={role}
              onChange={(e) => setRole(e.target.value)}
            />
          </div>

          <button type="submit" className="save-button">SAVE CHANGES</button>
        </form>
      </div>
      </div>

  
  
    );
  }
  
  export default Profile;